import React from "react"

const defaultState = {
  dark: false,
  modal: 'off',
  scroll_1: 300,
  scroll_2: 600,
  scroll_3: 400,
  scroll_4: 800,
  album: '',
  nav_up: false,
  toggleDark: () => {},
  togglePos_1: () => {},
  togglePos_2: () => {},
  togglePos_3: () => {},
  togglePos_4: () => {},
  toggleNavFlag: () => {},
  toggleModal: () => {},
  animateScrollDown: () => {},
}

const ThemeContext = React.createContext(defaultState)



class ThemeProvider extends React.Component {
  state = {
    dark: false,
    modal: 'off',
    album: '',
    ImageId: 0,
    scroll_1: 300,
    scroll_2: 600,
    scroll_3: 400,
    scroll_4: 800,
    nav_up: false
  }


  toggleAlbum = (string) => {
    let album = string
    this.setState({ album })
  }
  toggleImageId = (int) => {
    let imageId = int
    this.setState({ imageId })
  }

  togglePos_1 = (pos) => {
    let scroll_1 = pos
    this.setState({ scroll_1 })
  }
  togglePos_2 = (pos) => {
    let scroll_2 = pos
    this.setState({ scroll_2 })
  }
  togglePos_3 = (pos) => {
    let scroll_3 = pos
    this.setState({ scroll_3 })
  }
  togglePos_4 = (pos) => {
    let scroll_4 = pos
    this.setState({ scroll_4 })
  }

  toggleNavFlag = (bool) => {
    let nav_up = bool
    this.setState({ nav_up })
  }
  toggleModal = (str) => {
    let modal = str
    this.setState({ modal })
  }

  animateScrollDown = (state) => {
    console.log(this.state.modal);
  }

  componentDidMount() {


  }

  render() {
    const { children } = this.props
    const { dark, modal, scroll_1, scroll_2, scroll_3, scroll_4, nav_up, album, ImageId } = this.state
    return (
      <ThemeContext.Provider
        value={{
          dark,
          album,
          ImageId,
          modal,
          scroll_1,
          scroll_2,
          scroll_3,
          scroll_4,
          nav_up,
          toggleAlbum: this.toggleAlbum,
          toggleImageId: this.toggleImageId,
          togglePos_1: this.togglePos_1,
          togglePos_2: this.togglePos_2,
          togglePos_3: this.togglePos_3,
          togglePos_4: this.togglePos_4,
          toggleNavFlag: this.toggleNavFlag,
          toggleModal: this.toggleModal,
          animateScrollDown: this.animateScrollDown
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
