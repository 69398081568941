import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
//import "bootstrap/dist/js/bootstrap.min"

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment;
  return <ThemeProvider><Layout>{element}</Layout></ThemeProvider>
}
