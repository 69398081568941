module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Lilley Mansion Bed and Breakfast","short_name":"Lilley Mansion, Spring Lake, MI","start_url":"/","background_color":"#9b7a4b","theme_color":"#ffffff","display":"minimal-ui","crossOrigin":"use-credentials","icon":"src/images/favicon.png","icons":[{"src":"/lm-icons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/lm-icons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/lm-icons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/lm-icons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/lm-icons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d6a2f656d2a90d7f62f03f83251ac4c0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
